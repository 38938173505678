<template>
  <div
    v-if="
      permissions.includes('view report') ||
        permissions.includes('process salary')
    "
  >
    <b-container fluid>
      <b-row>
        <b-col cols="6" sm="3" md="3" lg="3">
          <b-button class="mt-2" variant="success" @click="exportPDF()">
            PDF
          </b-button>
          <!-- <b-button class="" variant="success" @click="exportExcel()">
            EXPORT
          </b-button> -->
        </b-col>
        <b-col cols="12" sm="6" md="4" lg="4">
          <h3 align="center">รายงาน Canteen</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" class="mt-2">
          <b-form-select v-model="branchs">
            <b-form-select-option :value="null" disabled>
              เลือก สำนักงาน
            </b-form-select-option>
            <b-form-select-option
              v-for="item in available_branchs"
              :key="item.bch_id"
              :value="item.bch_id"
            >
              {{ item.brname_th }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" class="mt-2">
          <b-input-group>
            <b-form-select v-model="departments" :disabled="disabled_dep">
              <b-form-select-option :value="null" disabled>
                เลือก แผนก
              </b-form-select-option>
              <b-form-select-option
                v-for="item in available_departments"
                :key="item.dep_id"
                :value="item.dep_id"
              >
                {{ item.depname_th }}
              </b-form-select-option>
            </b-form-select>
            <b-input-group-append>
              <b-button variant="danger" @click="clearDepartment()">
                X
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" class="mt-2">
          <b-col xs="12" sm="12" md="6" lg="6" class="mt-2">
            <b-datepicker
              placeholder="วันที่เริ่ม"
              locale="th"
              v-model="from_date"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              required
            />
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" class="mt-2">
            <b-datepicker
              placeholder="วันที่สิ้นสุด"
              locale="th"
              v-model="to_date"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              required
            />
          </b-col>
        </b-col>
      </b-row>
    </b-container>
    <br />
    <b-container fluid>
      <b-table-simple sticky-header="750px" class="tablebordercolor">
        <b-thead head-variant="dark" class="theadbordercolor">
          <b-tr>
            <b-th>No.</b-th>
            <b-th>รหัสพนักงาน</b-th>
            <b-th sticky-column>ชื่อพนักงาน</b-th>
            <b-th>แผนก</b-th>
            <b-th>ตำแหน่ง</b-th>
            <b-th>ประเภท</b-th>
            <b-th>จำนวนการสแกนหน้า</b-th>
            <b-th>จำนวนการสแกนนิ้วมือ</b-th>
            <b-th>วันที่</b-th>
            <b-th>ช่วงเวลา</b-th>
            <b-th>สถานที่</b-th>
          </b-tr>
        </b-thead>
        <b-tbody class="background-white">
          <b-tr
            v-for="(items, index) in data_canteen"
            :key="items.id + 'data_canteen' + index"
          >
            <b-td>{{ index + 1 }}</b-td>
            <b-td>{{ items.emp_code }}</b-td>
            <b-th sticky-column>
              {{ items.firstname_en + ' ' + items.lastname_en }}
            </b-th>
            <b-th>{{ items.depname_en }}</b-th>
            <b-th>{{ items?.posname_en }}</b-th>
            <b-th>ประเภท</b-th>
            <b-th>จำนวนการสแกนหน้า</b-th>
            <b-th>จำนวนการสแกนนิ้วมือ</b-th>
            <b-th>วันที่</b-th>
            <b-th>ช่วงเวลา</b-th>
            <b-th>สถานที่</b-th>
          </b-tr>
        </b-tbody>
        <!-- <b-tfoot foot-variant="dark">
          <b-tr>
            <b-th></b-th>
            <b-th></b-th>
          </b-tr>
        </b-tfoot> -->
      </b-table-simple>
    </b-container>
    <b-container>
      <b-table-simple small caption-top stacked>
        <b-tbody class="background-white">
          <b-tr>
            <b-td stacked-heading="จำนวนคน :">
              <!-- {{ sum_all_property.person_number }} -->
            </b-td>
            <b-td stacked-heading="จำนวนสแกนหน้า/ครั้ง :">
              <!-- {{ sum_all_property.salary_amt }} -->
            </b-td>
            <b-td stacked-heading="จำนวนสแกนนิ้ว/ครั้ง :">
              <!-- {{ sum_all_property.salary_amt }} -->
            </b-td>
            <b-td stacked-heading="รวมเงิน :">
              <!-- {{ sum_all_property.total_salary }} -->
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-container>
  </div>
</template>
<script>
import moment from 'moment';
import Vuex from 'vuex';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';

export default {
  name: 'CanteenReport',
  data() {
    return {
      profile: null,
      available_branchs: null,
      available_departments: [],
      data_canteen: [
        {
          emp_code: '72105001',
          firstname_en: 'Sorasan',
          lastname_en: 'Parasu',
          depname_en: 'Programmer',
        },
      ],
      branchs: null,
      departments: null,
      from_date: null,
      to_date: null,
      disabled_dep: true,
    };
  },
  async created() {
    moment.locale('th');

    loading_start();

    let params = { profile: true };
    let profile = await this.$store.dispatch('staffs/searchStaff', params);
    this.profile = { ...profile[0] };
    await this.getBranchs();

    loading_close();
  },
  mounted() {},
  props: {},
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },
  watch: {
    branchs: async function() {
      await this.getReport();
      if (this.available_departments.length > 0) {
        this.disabled_dep = false;
      } else {
        this.disabled_dep = true;
      }
      this.clearDepartment();
    },
    departments: function() {
      // this.getReport();
    },
    from_date: function() {
      // this.getReport();
    },
    to_date: function() {
      // this.getReport();
    },
  },
  methods: {
    async getBranchs() {
      let params = {
        com_id: this.profile.com_id,
        bch_only: true,
      };

      loading_start();
      this.available_branchs = await this.$store.dispatch(
        'staffs/searchStaff',
        params,
      );
      loading_close();
    },

    async getReport() {
      loading_start();
      let params = {
        ...{ bch_id: this.branchs ? this.branchs : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
        ...{
          from_date: this.from_date ? this.from_date : {},
        },
        ...{
          to_date: this.to_date ? this.to_date : {},
        },
      };

      this.data_canteen = null;
      // let data_canteen = await this.$store.dispatch('canteen/getReport', params);
      // console.log(data_canteen);

      loading_close();
    },

    clearDepartment() {
      this.departments = null;
      return;
    },

    async exportPDF() {
      if (typeof this.report_select['years'] == 'undefined') {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกรายงาน');
        return false;
      }
      var notformat = [
        'id',
        'rp_id',
        'emp_id',
        'emp_code',
        'emptname_th',
        'empfname_th',
        'emplname_th',
        'emptname_en',
        'empfname_en',
        'emplname_en',
        'empposname_th',
        'empposname_en',
        'fullname_th',
        'fullname_en',
        'stafftype',
        'bchname_th',
        'bch_nameth',
        'bchname_en',
        'comname_th',
        'comname_en',
        'depname_th',
        'depname_en',
        'salary_amt',
        'salary_amt_decode',
        'ot_hr',
        'ot1_hr',
        'ot1_5_hr',
        'ot2_hr',
        'ot2_hr',
        'date_send',
        'empid_send',
        'emp_code_send',
        'emptname_th_send',
        'empfname_th_send',
        'emplname_th_send',
        'emptname_en_send',
        'empfname_en_send',
        'emplname_en_send',
        'from_date',
        'to_date',
        'pay_date',
        'empposname_th_send',
        'empposname_en_send',
        'created_at',
        'updated_at',
      ];
      var headername = {};
      headername.name = 'ประจำงวดจ่าย';
      var pdfname = 'รายงานเงินเดือน';
      let params = {
        months: this.report_select['months'],
        years: this.report_select['years'],
        bch_id: this.report_select['bch_id'],
        from_date: this.report_select['from_date'],
        to_date: this.report_select['to_date'],
        chk_permis: 1,
      };
      // if (this.permissions.includes('debug')) {
      //   params = {
      //     months: 7,
      //     years: 2022,
      //     bch_id: 2,
      //   };
      // }

      var body = [];
      body = await this.$store.dispatch('salarys/slipSalary', params);
      var font = await this.$store.dispatch('exports/fontthsarabunnew');
      if (body.length <= 0) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return;
      }
      headername.bch_nameth = body[0].bch_nameth;
      headername.pay_date = this.report_select.name_pay_date_full_th;
      headername.name_fromdate_th = this.report_select.name_fromdate_th;
      headername.name_todate_th = this.report_select.name_todate_th;

      const doc = new jsPDF('landscape');
      doc.addFileToVFS('THSarabunNew.ttf', font);
      doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
      doc.setFont('THSarabunNew');
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text(
        headername.bch_nameth +
          '\n' +
          headername.name +
          ' ' +
          headername.pay_date +
          ' ( ' +
          headername.name_fromdate_th +
          ' - ' +
          headername.name_todate_th +
          ' )',
        148,
        10,
        'center',
      );
      var rows = [];
      var nf = new Intl.NumberFormat('en-US');
      var m = Math;
      body.forEach((val) => {
        let temp;
        temp = val;
        temp.salary_amt = val.salary_amt.slice(0, val.salary_amt.length - 1);
        temp.fullname_th = Object.assign(
          val.emptname_th + val.empfname_th + ' ' + val.emplname_th,
        );
        if (val.emptname_en.length > 0) {
          val.emptname_en = val.emptname_en + ' ';
        } else {
          val.emptname_en = '';
        }
        temp.fullname_en = Object.assign(
          val.emptname_en + val.empfname_en + ' ' + val.emplname_en,
        );
        temp.fullname_en = Object.assign(
          val.emptname_en + val.empfname_en + ' ' + val.emplname_en,
        );
        var privatecode_st = '';
        if (
          localStorage.getItem('privatecode') ||
          this.permissions.includes('reportnotprivatecode')
        ) {
          if (localStorage.getItem('privatecode')) {
            privatecode_st = localStorage.getItem('privatecode');
            this.disabled_privatecode = true;
          }
          if (
            this.securePrivatecode(privatecode_st) ||
            this.permissions.includes('reportnotprivatecode')
          ) {
            temp.salary_amt_decode = this.decodeBase64(temp.salary_amt);
            temp.salary_amt_decode = temp.salary_amt = nf.format(
              temp.salary_amt_decode,
            );
          }
        }
        Object.keys(temp).forEach((key) => {
          if (!notformat.includes(key)) {
            temp[key] = nf.format(m.round(temp[key]).toFixed(2));
          }
        });
        rows.push(temp);
      });
      rows.forEach((value, index) => {
        var no = index + 1;
        rows[index]['no'] = no;
      });

      let total = {
        empposname_th: 'รวม',
        salary_amt: this.sum_all_property['salary_amt'],
        daywork: this.sum_all_property['daywork'],
        total_salary: this.sum_all_property['total_salary'],
        ot_total: this.sum_all_property['ot_total'],
        ot_bath: this.sum_all_property['ot_bath'],
        total_svc: this.sum_all_property['total_svc'],
        massage_income: this.sum_all_property['massage_income'],
        food_service: this.sum_all_property['food_service'],
        tip: this.sum_all_property['tip'],
        accrued_income: this.sum_all_property['accrued_income'],
        bonus: this.sum_all_property['bonus'],
        allowance: this.sum_all_property['allowance'],
        keepdays_baht: this.sum_all_property['keepdays_baht'],
        income_total: this.sum_all_property['income_total'],
        notwork_bath: this.sum_all_property['notwork_bath'],
        sso_bath: this.sum_all_property['sso_bath'],
        tax_bath: this.sum_all_property['tax_bath'],
        cost_total: this.sum_all_property['cost_total'],
        income_net: this.sum_all_property['income_net'],
      };
      rows.push(total);
      var zero = [];
      $.each(this.sum_all_property, function(index, el) {
        if (el == 0) {
          zero.push(index);
        }
      });

      var columns = [
        { header: 'ที่', dataKey: 'no' },
        { header: 'ID', dataKey: 'emp_code' },
        { header: 'ชื่อพนักงาน', dataKey: 'fullname_th' },
        { header: 'แผนก', dataKey: 'depname_en' },
        { header: 'ตำแหน่ง', dataKey: 'empposname_en' },
        // { header: 'แผนก', dataKey: 'depname_th' },
        // { header: 'ตำแหน่ง', dataKey: 'empposname_th' },
        { header: 'เงินเดือน', dataKey: 'salary_amt' },
        { header: 'วัน', dataKey: 'daywork' },
        { header: 'รวมเงินเดือน', dataKey: 'total_salary' },
        { header: 'OT', dataKey: 'ot_total' },
        { header: 'OT เหมา', dataKey: 'ot_bath' },
        { header: 'SVC', dataKey: 'total_svc' },
        { header: 'ค่านวดสปา', dataKey: 'massage_income' },
        { header: 'เบี้ยเลี้ยง', dataKey: 'food_service' },
        { header: 'ทิป', dataKey: 'tip' },
        { header: 'จ้างค้างรับ', dataKey: 'accrued_income' },
        { header: 'Bonus', dataKey: 'bonus' },
        { header: 'ค่าตำแหน่ง', dataKey: 'allowance' },
        { header: 'คืนวันหยุด', dataKey: 'keepdays_baht' },
        { header: 'รวมเงินได้', dataKey: 'income_total' },
        { header: 'ขาดงาน/WOP', dataKey: 'notwork_bath' },
        { header: 'SSF', dataKey: 'sso_bath' },
        { header: 'TAX', dataKey: 'tax_bath' },
        { header: 'รวมรายจ่าย', dataKey: 'cost_total' },
        { header: 'เงินได้สุทธิ', dataKey: 'income_net' },
      ];
      var newcolumns = [];
      columns.forEach((v) => {
        if (!zero.includes(v.dataKey)) {
          newcolumns.push(v);
        }
      });
      var styles = {
        fontSize: 8,
        font: 'THSarabunNew',
        cellPadding: 0.8,
      };
      var sortedDesc = this.persons.reverse();
      var result = sortedDesc.map((item, i) => {
        var c = item.titlename_th
          .concat(item.firstname_th)
          .concat(' ')
          .concat(item.lastname_th)
          .concat(' ')
          .concat(item.position_detail);
        return c;
      });
      var footer_sign = '';
      var cout = 0;
      for (var i = 0; i < result.length; i++) {
        footer_sign = footer_sign
          .concat(result[i])
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ')
          .concat(' ');
        cout++;
      }
      var checkperson = 25;
      if (cout > 5) {
        checkperson = 0;
      }
      var config = {
        startY: 22, // ตำแหน่งที่เริ่ม
        startX: 2, // ตำแหน่งที่เริ่ม
        theme: 'grid',
        styles: styles,
        margin: { left: 5, right: 5, bottom: 30 },
        showHead: 'everyPage',
        columns: newcolumns,
        body: rows,
        columnStyles: {
          no: {
            halign: 'center',
            //cellWidth: 8,
          },
          emp_code: {
            //cellWidth: 13,
          },
          fullname_th: {
            //cellWidth: 16,
          },
          depname_th: {
            //cellWidth: 16,
          },
          empposname_th: {
            //cellWidth: 16,
          },
          salary_amt: {
            halign: 'right',
            //cellWidth: 13,
          },
          daywork: {
            halign: 'right',
            //cellWidth: 6,
          },
          total_salary: {
            halign: 'right',
            //cellWidth: 13,
          },
          ot_total: {
            halign: 'right',
            //cellWidth: 10,
          },
          ot_bath: {
            halign: 'right',
            //cellWidth: 10,
          },
          total_svc: {
            halign: 'right',
            //cellWidth: 10,
          },
          massage_income: {
            halign: 'right',
            //cellWidth: 12,
          },
          food_service: {
            halign: 'right',
            //cellWidth: 10,
          },
          tip: {
            halign: 'right',
            //cellWidth: 10,
          },
          accrued_income: {
            halign: 'right',
            //cellWidth: 11,
          },
          bonus: {
            halign: 'right',
            //cellWidth: 10,
          },
          allowance: {
            halign: 'right',
            //cellWidth: 12,
          },
          keepdays_baht: {
            halign: 'right',
            //cellWidth: 11,
          },
          income_total: {
            halign: 'right',
            //cellWidth: 14,
          },
          notwork_bath: {
            halign: 'right',
            //cellWidth: 13.5,
          },
          sso_bath: {
            halign: 'right',
            //cellWidth: 11,
          },
          tax_bath: {
            halign: 'right',
            //cellWidth: 9,
          },
          cost_total: {
            halign: 'right',
            //cellWidth: 12,
          },
          income_net: {
            halign: 'right',
            //cellWidth: 12,
          },
        },
        didDrawPage: function(data) {
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.setFontSize(12);
          doc.setTextColor(0, 0, 0);
          doc.text(
            footer_sign,
            data.settings.margin.left + checkperson,
            pageHeight - 10,
          );
        },
      };

      doc.autoTable(config);
      doc.setProperties({
        title: pdfname,
      });
      window.open(doc.output('bloburl'), '_blank');
      // doc.save(pdfname + '.pdf'); // download
    },

    number_format(num = 0) {
      let new_num = parseFloat(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return new_num;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');
.box-header-salary {
  text-align: center;
  font-weight: bold;
  font-family: 'Kanit';
  padding: 1em 0;
}
.tablebordercolor .theadbordercolor > tr > th {
  background-color: #343a40;
  border-color: #ddd;
}
.background-white {
  background-color: white;
}
.font-imp {
  font-size: 18px !important;
  color: rgb(255, 255, 255) !important;
}
.font-size {
  font-size: 12px !important;
}
.photo {
  width: 54px;
  height: 54px;
  overflow: hidden;
  border-radius: 50%;
}
.imgclass {
  text-align: center;
  width: 250px !important;
  height: 250px !important;
  overflow: hidden !important;
  display: inline-block;
}
/*
canvas.signature-pad {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
}
.tb-salary {
  .th-w-100 {
    min-width: 100px;
  }
  .th-w-150 {
    min-width: 150px;
  }
  .th-w-200 {
    min-width: 200px;
  }
  .th-w-250 {
    min-width: 250px;
  }
  .th-w-300 {
    min-width: 300px;
  }

  thead th {
    min-width: 100px;
    text-align: center;
    font-size: 14px;
  }
  tbody th,
  tbody td {
    text-align: center;
  }
}
*/
</style>
