<template>
  <div v-if="staffkeepdaylist">
    <md-card>
      <div class="header" @click="headdropdown()">
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <!-- <div class="card-icon">
          <md-icon>flight</md-icon>
        </div> -->
          <div class="card-icon">
            <md-icon>edit_calendar</md-icon>
          </div>
          <h4 class="title">
            เปลี่ยนวันหยุด (Change Day Off)
            <!-- <span style="font-size: 16px">
              วันเก็บคงเหลือ {{ keepdays_amount }}
            </span> -->
          </h4>
        </md-card-header>
      </div>
      <md-card-content class="contentleave">
        <md-table
          class="minheight"
          v-model="staffkeepdaylist"
          :md-update="'true'"
          v-if="Check_Join && staffkeepdaylist.length"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="No.">
              {{ staffkeepdaylist.indexOf(item) + 1 }}
            </md-table-cell>
            <md-table-cell md-label="เก็บวันหยุด">
              {{ item.from_date_format }} <br />
              {{ item.from_date_time }} - {{ item.to_date_time }} <br />
              <span v-if="item.keep_type == 'hm'">
                ช่วงเช้า
              </span>
              <span v-else-if="item.keep_type == 'hn'">
                ช่วงบ่าย
              </span>
              <span v-else>เต็มวัน</span>
              <div v-if="item.approve_status == 'request'">
                <b-button @click="deleteKeepday(item)" variant="danger">
                  ลบ
                </b-button>
              </div>
              <div v-if="item.notes">
                <hr />
                <p>หมายเหตุ : {{ item.notes }}</p>
              </div>
            </md-table-cell>
            <md-table-cell md-label="ขอทำงานวันหยุด">
              <div v-if="item.approve_status == 'request'">
                <span style="color: blue">
                  รอการอนุมัติขอทำงานวันหยุด
                </span>
              </div>
              <div v-else-if="item.approve_status == 'approve'">
                {{ item.approver_firstname }}
                {{ item.approver_lastname }}<br />
                <span style="color: green">อนุมัติแล้ว</span>
              </div>
              <div v-else-if="item.approve_status == 'disapprove'">
                {{ item.approver_firstname }}
                {{ item.approver_lastname }}<br />
                <span style="color: red">ไม่อนุมัติ</span>
              </div>
              <div v-else-if="item.approve_status == 'reject'">
                <!-- <b-button @click="editleave(item)" size="sm" variant="warning">
                  แก้ไข
                </b-button> -->
              </div>
            </md-table-cell>
            <md-table-cell md-label="เปลี่ยนวันหยุด">
              <div v-if="item.active == 1 && profileMe.stafftype == 'month'">
                <div v-if="item.leave.length > 0">
                  <md-table-row
                    v-for="leave in item.leave"
                    :key="
                      leave.from_date +
                        leave.to_date +
                        leave.to_date_type +
                        'ch'
                    "
                    class="hr"
                  >
                    {{ leave.to_date }}
                    <span v-if="leave.to_date_type == 'hm'">
                      ลาช่วงเช้า
                    </span>
                    <span v-else-if="leave.to_date_type == 'hn'">
                      ลาช่วงบ่าย
                    </span>
                    <span v-else>เต็มวัน</span>
                  </md-table-row>
                </div>
                <div v-if="item.active_half > 0">
                  <md-table-row>
                    <hr v-if="item.leave.length > 0" />
                    <b-datepicker
                      :min="item.from_date"
                      placeholder="ลาเปลี่ยนวันหยุด"
                      locale="th"
                      v-model="item.to_date_use"
                      :date-disabled-fn="disabledDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      required
                    ></b-datepicker>
                    <!-- <md-datepicker v-model="item.to_date" md-immediately>
                      <label>เลือกวันที่ต้องการเปลี่ยน</label>
                    </md-datepicker> -->
                    <md-checkbox
                      v-model="item.is_leaveend_half"
                      v-if="item.active_half >= 1"
                    >
                      <span class="font-black">ลาครึ่งวัน</span>
                    </md-checkbox>
                    <div v-else>
                      <span style="display:none">
                        {{ (item.is_leaveend_half = true) }}
                      </span>
                    </div>
                    <md-field v-show="item.is_leaveend_half">
                      <label>เลือกช่วงการลา</label>
                      <md-select v-model="item.to_date_type">
                        <md-option value="hm">ช่วงเช้า</md-option>
                        <md-option value="hn">ช่วงบ่าย</md-option>
                      </md-select>
                    </md-field>
                  </md-table-row>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell md-label="ขอเปลี่ยนวันหยุด">
              <md-table-row
                v-for="leave in item.leave"
                :key="
                  leave.from_date +
                    leave.to_date_type +
                    leave.to_date +
                    leave.approve_status +
                    'ch_leave'
                "
                class="hr"
              >
                <div v-if="leave.approve_status == 'leaverequest'">
                  <span style="color: blue">รอการอนุมัติ</span>
                </div>
                <div v-else-if="leave.approve_status == 'approve'">
                  {{ leave.approver_firstname }}
                  {{ leave.approver_lastname }}<br />
                  <span style="color: green">อนุมัติแล้ว</span>
                </div>
                <div v-else-if="leave.approve_status == 'disapprove'">
                  {{ leave.approver_firstname }}
                  {{ leave.approver_lastname }}<br />
                  <span style="color: red">ไม่อนุมัติ</span>
                </div>
                <div v-else-if="leave.approve_status == 'reject'">
                  <!-- <md-button
                    @click="editleave(leave, 'oneday')"
                    class="md-round md-warning"
                  >
                    แก้ไข
                  </md-button> -->
                </div>
              </md-table-row>
            </md-table-cell>
            <!-- <md-table-cell md-label="ผู้อนุมัติ">
              <md-table-row
                v-for="leave in item.leave"
                :key="leave.approve_status + leave.from_date + leave.to_date"
                class="hr"
              >
                <div v-if="leave.approve_status == 'approve'">
                  <span>
                    {{ item.approver_firstname }}
                    {{ item.approver_lastname }}
                  </span>
                </div>
                <div v-else-if="leave.approve_status == 'disapprove'">
                  <span>
                    {{ item.approver_firstname }} {{ item.approver_lastname }}
                  </span>
                </div>
              </md-table-row>
            </md-table-cell> -->
            <!-- <md-table-cell md-label="ฝ่ายบุคคล"></md-table-cell> -->
            <md-table-cell md-label="Action">
              <md-table-row
                v-for="leave in item.leave"
                :key="
                  leave.from_date +
                    leave.to_date_type +
                    leave.to_date +
                    leave.approve_status +
                    'ch_action'
                "
                class="hr"
              >
                <b-button
                  v-if="leave.approve_status == 'leaverequest'"
                  @click="deleteLeave(leave)"
                  size="md"
                  variant="danger"
                >
                  ลบ
                </b-button>
              </md-table-row>
              <md-table-row v-if="item.active == 1 && item.active_half > 0">
                <div
                  v-if="
                    item.approve_status == 'approve' &&
                      profileMe.stafftype == 'month'
                  "
                >
                  <b-button
                    @click="createleave(item)"
                    variant="outline-success"
                  >
                    เปลี่ยนวันหยุด
                  </b-button>
                </div>
                <div v-if="profileMe.stafftype == 'day'"></div>
              </md-table-row>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div
          v-if="
            !old_leave &&
              !reqkeepdaydisable &&
              moment(years + '-01-01').year() >= moment().year()
          "
        >
          <br />
          <md-divider style="opacity: 1"></md-divider>
          <div style="padding: 8px 0px"></div>
        </div>

        <!-- ขอทำงานวันหยุด -->

        <ValidationObserver
          v-if="
            !old_leave &&
              !reqkeepdaydisable &&
              moment(years + '-01-01').year() >= moment().year()
          "
        >
          <form @submit.prevent="submit">
            <div class="md-layout spacing md-alignment-center md-gutter">
              <div class="md-layout-item md-size-100">
                <h5>ขอทำงานวันหยุด</h5>
              </div>
              <div class="md-layout-item md-size-100">
                <div class="md-layout spacing md-alignment-center md-gutter">
                  <div
                    class="md-layout-item spacing md-size-25 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                  >
                    <ValidationProvider
                      style="margin-bottom: 10px;"
                      name="fromdate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <b-datepicker
                        class="space-bt"
                        variant="primary"
                        placeholder="วันที่เริ่ม"
                        locale="th"
                        v-model="fromdate"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                        :date-disabled-fn="disabledKeepDatesfrom"
                      >
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed">
                            done
                          </md-icon>
                        </slide-y-down-transition>
                      </b-datepicker>
                    </ValidationProvider>
                    <ValidationProvider
                      name="time_fromdate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <VueCtkDateTimePicker
                        id="time_fromdate"
                        input-size="lg"
                        v-model="time_fromdate"
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                        :only-time="true"
                        :noLabel="true"
                        :minute-interval="15"
                        :disable-date="true"
                        :format="'HH:mm'"
                        :formatted="'HH:mm'"
                        :label="'เวลาที่เริ่ม'"
                        :error="!passed && failed"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item spacing md-size-25 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                  >
                    <ValidationProvider
                      name="todate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <b-datepicker
                        class="space-bt"
                        variant="primary"
                        placeholder="วันที่สิ้นสุด"
                        locale="th"
                        v-model="todate"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                        :date-disabled-fn="disabledKeepDatesto"
                      >
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed">
                            done
                          </md-icon>
                        </slide-y-down-transition>
                      </b-datepicker>
                    </ValidationProvider>
                    <ValidationProvider
                      name="time_todate"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <VueCtkDateTimePicker
                        id="time_todate"
                        input-size="lg"
                        v-model="time_todate"
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                        :only-time="true"
                        :noLabel="true"
                        :minute-interval="15"
                        :disable-date="true"
                        :format="'HH:mm'"
                        :formatted="'HH:mm'"
                        :label="'เวลาที่สิ้นสุด'"
                        :overlay="false"
                        :error="!passed && failed"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item spacing md-size-20 md-medium-size-25 md-small-size-40 md-xsmall-size-100"
                  >
                    <md-field>
                      <label>เลือกช่วงการทำงาน</label>
                      <md-select v-model="keep_type">
                        <md-option value="f">เต็มวัน</md-option>
                        <md-option value="hm">ช่วงเช้า</md-option>
                        <md-option value="hn">ช่วงบ่าย</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div
                    class="md-layout-item md-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
                    v-if="diff_time.hours > 0 || diff_time.minutes > 0"
                  >
                    <p>
                      เวลาที่ทำงาน {{ diff_time.hours }} ชั่วโมง
                      {{ diff_time.minutes }} นาที
                    </p>
                  </div>
                  <div
                    class="md-layout-item spacing md-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                  >
                    <ValidationProvider
                      name="notes"
                      rules="min:4"
                      v-slot="{ passed, failed }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                        ]"
                      >
                        <label>หมายเหตุ</label>
                        <md-textarea v-model="notes"></md-textarea>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed">
                            done
                          </md-icon>
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                  <div
                    class="md-layout-item md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-40"
                  >
                    <b-button type="submit" pill variant="success">
                      ขอทำงานวันหยุด
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>

        <!-- ขอทำงานวันหยุด -->
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
// import { Tabs } from '@/components';
// import $ from 'jquery';
import Vuex from 'vuex';
import _ from 'lodash';
import moment, { months } from 'moment';
import employeeMixin from '@/mixins/employee-mixin';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
  },
  mixins: [employeeMixin],
  data() {
    return {
      reqkeepdaydisable: false,
      permissions: [],
      staffkeepdaylist: [],
      keepdays_amount: 0,
      Check_Join: false,
      value: null,
      disabled_min_date: null,
      employee_id: null,
      fromdate: null,
      todate: null,
      time_fromdate: null,
      time_todate: null,
      keep_type: 'f',
      diff_time: {
        hours: 0,
        minutes: 0,
      },
      notes: '',
      moment: moment,
      showProgressBar: false,
      disabledFromDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment();
        let datediff = fdate.diff(tdate);
        // return datediff <= 0 || day == 0 || day == 6;
        return datediff <= 0;
      },

      changedate: null,
      keepdays: {
        type: 'keepdays',
        employee_id: null,
        from_date: null,
        to_date: null,
        keep_type: null,
        approve_status: 'request',
        notes: '',
      },
      disabled_submit: false,
    };
  },
  props: {
    old_leave: {
      type: Boolean,
      default: false,
    },
    leavechangedayoff: {
      type: Array,
      default: () => ({}),
    },
    years: {
      type: Number,
      default: moment().year(),
    },
    persons: {
      type: Number,
      default: 0,
    },
    profileMe: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.Get_Staffkeepdaylist();
    this.KeepdayJoinLeave();
    this.getpermissions();
  },
  updated() {},
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },

  watch: {
    years: async function() {
      await this.getKeepJoinLeave();
    },
    leavechangedayoff: async function() {
      if (this.leavechangedayoff.length) {
        this.Check_Join = false;
        this.KeepdayJoinLeave();
      }
    },
    staffkeepdaylist: async function() {
      this.Check_Join = false;
      this.KeepdayJoinLeave();
    },
    fromdate() {
      this.diff_Time();
    },
    todate() {
      this.diff_Time();
    },
    time_fromdate() {
      this.diff_Time();
    },
    time_todate() {
      this.diff_Time();
    },
  },
  methods: {
    getpermissions() {
      var me = this.me;
      me.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      me.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
    async getKeepJoinLeave() {
      await this.Get_Staffkeepdaylist();
      this.Check_Join = false;
      await this.KeepdayJoinLeave();
    },
    async Get_Staffkeepdaylist() {
      this.keepdays_amount = 0;
      var year = this.years;
      let params = {
        ...{ year: year },
        ...{ emp_id: this.profileMe.id },
      };
      const staffkeepdaylist = await this.$store.dispatch(
        'keepdays/staffkeepdaylist',
        params,
      );
      if (staffkeepdaylist) {
        // var del = ['to_date'];
        var del = [];
        this.staffkeepdaylist = await staffkeepdaylist.map((val) => {
          let fromdate = moment(val.from_date).format('DD/MM/YYYY');
          let requestdate = moment(val.requestdate).format('DD/MM/YYYY');

          let fromdate_time = moment(val.from_date);
          let todate_time = moment(val.to_date);
          var datediff_hours = todate_time.diff(fromdate_time, 'hours');
          var datediff_minutes = todate_time.diff(fromdate_time, 'minutes');

          val.from_date_time = moment(val.from_date).format('HH:mm');
          val.to_date_time = moment(val.to_date).format('HH:mm');
          val.from_date_format = fromdate;
          val.request_date_format = requestdate;
          val.hours_time = datediff_hours;
          val.minutes_time = datediff_minutes % 60;

          del.forEach((del) => delete val[del]);

          if (
            moment(val.from_date).add(1, 'days') <= moment() &&
            val.approve_status === 'approve'
          ) {
            val.active = 1;
            // กำหนดชั่วโมง - ครึ่งวัน&เต็มวัน
            if (val.keep_type == 'f') {
              val.day = 1;
              this.keepdays_amount++;
            } else {
              val.day = 0.5;
              this.keepdays_amount += 0.5;
            }
          } else {
            val.active = 0;
          }
          return val;
        });
      } else {
        this.staffkeepdaylist = [];
        this.keepdays_amount = 0;
      }
    },
    async KeepdayJoinLeave() {
      await this.staffkeepdaylist.forEach((keepdays) => {
        var l = [];
        keepdays.active_half = keepdays.day;
        this.leavechangedayoff.forEach((leave) => {
          let from_date = moment(leave.from_date, 'DD/MM/YYYY').format(
            'YYYY-MM-DD',
          );
          let keepday_from_date = moment(keepdays.from_date).format(
            'YYYY-MM-DD',
          );
          if (from_date == keepday_from_date) {
            l.push(leave);
            if (leave.approve_status === 'approve') {
              if (leave.to_date_type != 'f') {
                keepdays.active_half -= 0.5;
                this.keepdays_amount -= 0.5;
              } else {
                keepdays.active_half -= 1;
                this.keepdays_amount -= 1;
              }
            } else if (leave.approve_status === 'disapprove') {
            } else {
              if (leave.to_date_type != 'f') {
                keepdays.active_half -= 0.5;
                this.keepdays_amount -= 0.5;
              } else {
                keepdays.active_half -= 1;
                this.keepdays_amount -= 1;
              }
            }
          }
        });
        keepdays.leave = l;
      });
      Promise.all(this.staffkeepdaylist);
      this.Check_Join = true;
    },

    disabledDate(passdate) {
      let date = moment(passdate);
      let now = moment();
      let datediff = now.diff(date, 'days');
      return datediff > 7;
    },

    disabledKeepDatesfrom(passdate) {
      let date = moment(passdate);
      let now = moment();
      let datediff = now.diff(date, 'days');
      return datediff > 7;
    },

    disabledKeepDatesto(passdate) {
      let fdate = moment(passdate);
      let tdate = moment(this.fromdate);
      let datediff = fdate.diff(tdate, 'days');

      return datediff < 0 || datediff > 1;
    },

    deleteKeepday(keep) {
      this.$emit('deleteKeepday', keep);
    },
    deleteLeave(leave) {
      this.$emit('deleteLeave', leave);
    },
    diff_Time() {
      let datediff = moment(this.todate, 'DD/MM/YYYY').diff(
        moment(this.fromdate, 'DD/MM/YYYY'),
        'days',
      );
      if (datediff < 0 || datediff > 1) {
        this.todate = this.fromdate;
      }
      if (
        this.fromdate &&
        this.todate &&
        this.time_fromdate &&
        this.time_todate
      ) {
        let fromdate = moment(this.fromdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let todate = moment(this.todate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let fromdate_time = moment(fromdate + ' ' + this.time_fromdate);
        let todate_time = moment(todate + ' ' + this.time_todate);
        var datediff_hours = todate_time.diff(fromdate_time, 'hours');
        var datediff_minutes = todate_time.diff(fromdate_time, 'minutes');
      }

      if (datediff_hours >= 0 || datediff_minutes >= 0) {
        this.diff_time.hours = datediff_hours;
        this.diff_time.minutes = datediff_minutes % 60;
      }
    },
    async submit() {
      if (moment(this.fromdate) != null) {
        var fromdate = moment(this.fromdate).format('YYYY-MM-DD');
        var todate = moment(this.todate).format('YYYY-MM-DD');
      } else {
        return false;
      }

      let fromdate_time = moment(fromdate + ' ' + this.time_fromdate).format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let todate_time = moment(todate + ' ' + this.time_todate).format(
        'YYYY-MM-DD HH:mm:ss',
      );

      if (!this.me.employee_id) {
        await this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      if (!moment(fromdate_time).isBefore(todate_time)) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกเวลาให้ถูกต้อง');
        return false;
      }
      this.keepdays.approve_status = 'request';
      this.keepdays.requestdate = moment().format('YYYY-MM-DD');
      this.keepdays.employee_id = this.me.employee_id;
      this.keepdays.from_date = fromdate_time;
      this.keepdays.to_date = todate_time;
      this.keepdays.keep_type = this.keep_type;
      this.keepdays.notes = this.notes;

      try {
        let response = await this.$store.dispatch(
          'keepdays/addStaffKeepDay',
          this.keepdays,
        );
        if (response.errors) {
          await this.$store.dispatch('alerts/error', response.errors);
        } else {
          this.$store.dispatch('alerts/success', 'สำเร็จเรียบร้อยแล้ว');
          await this.Get_Staffkeepdaylist();

          this.time_fromdate = '';
          this.fromdate = '';
          this.todate = '';
          this.time_todate = '';
          this.keep_type = '';
          this.notes = '';
        }
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },

    createleave(item) {
      var newitem = {};
      if (!item.to_date_use) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกวันที่ต้องการเปลี่ยน');
        return false;
      }

      if (
        moment(item.to_date_use, 'DD/MM/YYYY').isBefore(
          moment(item.from_date),
        ) ||
        moment(item.to_date_use, 'DD/MM/YYYY') == moment(item.from_date)
      ) {
        this.$store.dispatch(
          'alerts/error',
          'ไม่สามารถเปลี่ยนวันหยุดหลังวันเก็บได้',
        );
        return false;
      }
      if (item.is_leaveend_half) {
        if (item.to_date_type) {
          newitem.to_date_type = item.to_date_type;
        } else {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกช่วงการลา');
          return false;
        }
      } else {
        newitem.from_date_type = 'f';
        newitem.to_date_type = 'f';
      }
      newitem.employee_id = item.employee_id;
      newitem.leave_id = 7;

      newitem.from_date = moment(item.from_date).format('YYYY-MM-DD');
      newitem.to_date = moment(item.to_date_use).format('YYYY-MM-DD');
      newitem.from_date_type = newitem.to_date_type;
      newitem.approve_status = 'leaverequest';
      newitem.notes = '';
      this.$emit('createleave', newitem);
    },
  },
};
</script>
<style></style>
