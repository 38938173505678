<template>
  <div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }]">
    <notifications />
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu />
      <template slot="links">
        <mobile-menu
          :totalnotify="totalNotify"
          :permissions="permissions"
          :approverOTOverLimit="approverOTOverLimit"
          :emp_id="employee_id"
        />
        <!-- <sidebar-item
          :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"
        /> -->
        <sidebar-item opened :link="{ name: 'Staff Details', image: image }">
          <sidebar-item
            :link="{ name: 'พนักงาน', path: '/staff/transaction' }"
          />
          <sidebar-item
            v-if="approverhas_emp.length > 0"
            :link="{
              name: 'กะพนักงาน (ของหัวหน้างาน)',
              nameen: 'Change Shift Work (For Manager)',
              path: '/admin/ChangeShiftWorkForManager',
            }"
          />
          <sidebar-item
            :link="{ name: 'บันทึกการลา (Leave)', path: '/staff/leave' }"
          />
          <sidebar-item
            :link="{ name: 'ทำงานล่วงเวลา (OT)', path: '/staff/otrequest' }"
          />
          <sidebar-item
            :link="{
              name: 'OT เหมา',
              path: '/staff/otrequestcharter',
            }"
          />
          <sidebar-item
            v-if="is_spa_dep.includes(profile?.[0]?.dep_id)"
            :link="{
              name: 'ค่านวดสปา',
              path: '/staff/sparequestcharter',
            }"
          />
          <sidebar-item
            :link="{
              name: 'santhiya SOS',
              path: '/admin/list_santhiayaSOS',
            }"
          />
          <sidebar-item
            :link="{
              name: 'ข้อเสนอแนะ / ข้อร้องเรียน',
              nameen: 'Suggestion',
              path: '/admin/requestAppeal',
            }"
          />
        </sidebar-item>
        <sidebar-item opened :link="{ name: 'Executive', image: image }">
          <sidebar-item
            v-if="permissions.includes('reportnotprivatecode')"
            :link="{
              name: 'รายงานเงินเดือน',
              path: '/executive/salary-report',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('reportflatrate')"
            :link="{
              name: 'รายงาน OT เหมา และค่านวดสปา',
              path: '/executive/flatreatspa-report',
            }"
          />
        </sidebar-item>
        <sidebar-item opened :link="{ name: 'Admin Manager', image: image }">
          <sidebar-item
            v-if="permissions.includes('manageAppeal')"
            :link="{
              name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
              nameen: 'Suggestion Report',
              path: '/admin/listAppeal',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('manageInfo')"
            :link="{
              name: 'จัดการข้อปฏิบัติ & แนะนำแผนก',
              nameen: 'Informations Setting',
              path: '/admin/InformationsSetting',
            }"
          />
          <!-- <sidebar-item
            :link="{
              name: 'ปฏิทินการทำงาน',
              path: '/staff/transaction/calendarworking',
            }"
          /> -->
          <sidebar-item
            v-if="
              permissions.includes('flatratemanage') ||
                permissions.includes('view flatreat')
            "
            :link="{
              name: 'จัดการข้อมูลค่านวดสปา',
              path: '/admin/editdata-flatreat',
            }"
          />
          <sidebar-item
            v-if="
              permissions.includes('process salary') ||
                permissions.includes('process Spa')
            "
            :link="{
              name: 'คำนวณค่านวดสปา',
              path: '/admin/spa-process',
            }"
          />
          <sidebar-item
            v-if="
              permissions.includes('process salary') ||
                permissions.includes('process flatrate')
            "
            :link="{
              name: 'คำนวณ OT เหมา',
              path: '/admin/otflatreat-process',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('view contract')"
            :link="{ name: 'ฟอร์มสัญญาจ้าง', path: '/admin/form-contract' }"
          />
          <sidebar-item
            v-if="permissions.includes('manage santhiyaSOS')"
            :link="{
              name: 'จัดการสัญธิญา SOS',
              path: '/admin/santhiayaSOS-management',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('view staff')"
            :link="{ name: 'จัดการพนักงาน', path: '/admin/list-users/' }"
          />
          <sidebar-item
            v-if="permissions.includes('view department')"
            :link="{ name: 'จัดการแผนก', path: '/admin/list-department' }"
          />
          <sidebar-item
            v-if="permissions.includes('view position')"
            :link="{ name: 'จัดการตำแหน่ง', path: '/admin/list-position' }"
          />
          <sidebar-item
            v-if="permissions.includes('view roles')"
            :link="{
              name: 'จัดการ Role',
              path: '/admin/role-management/list-roles',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('view permissions')"
            :link="{
              name: 'จัดการ Permission',
              path: '/admin/permission-management/list-permissions',
            }"
          />
          <!-- <sidebar-item
            v-if="permissions.includes('setup permission')"
            :link="{
              name: 'จัดการ Permission (ใหม่)',
              path: '/admin/permission-management-new',
            }"
          /> -->
          <sidebar-item
            v-if="permissions.includes('setup permission')"
            :link="{
              name: 'จัดการสิทธิ์การเข้าใช้งาน',
              path:
                '/admin/permission-access/list-permissions-access/id/type/byrole',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('user role')"
            :link="{
              name: 'จัดการสิทธิ์ User',
              path: '/admin/staff-role/list-staff-role/0/0',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('setup working time')"
            :link="{ name: 'ตั้งค่ากะทำงาน', path: '/admin/list-workingtime' }"
          />
          <sidebar-item
            v-if="permissions.includes('setup approve')"
            :link="{
              name: 'ตั้งค่าการอนุมัติ',
              path: '/admin/approve-setup',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('setup private code')"
            :link="{
              name: 'ตั้งค่า Privatecode',
              path: '/admin/privatecode-setup',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('reset password')"
            :link="{
              name: 'Reset รหัสผ่านพนักงาน',
              path: '/admin/admin-reset-password',
            }"
          />
        </sidebar-item>
        <sidebar-item opened :link="{ name: 'Payroll Manager', image: image }">
          <!-- <sidebar-item :link="{ name: 'เพิ่ม/แก้ไข รายได้ ค่าใช้จ่าย', path: '/admin/privatecode-setup'}" />
          <sidebar-item :link="{ name: 'บันทึกรายการประจำวัน', path: '/admin/privatecode-setup'}" /> -->
          <!-- <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตั้งค่างวดของเงินเดือน',
              path: '/payroll/salary-period',
            }"
          /> -->
          <sidebar-item
            v-if="permissions.includes('editdata')"
            :link="{
              name: 'จัดการข้อมูล',
              path: '/payroll/editdata',
            }"
          />
          <!-- remain -->
          <sidebar-item
            v-if="permissions.includes('view remainleave')"
            :link="{
              name: 'จัดการวันลาคงเหลือ',
              path: '/payroll/reportremainleave',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ดึงข้อมูล',
              path: '/payroll/exportpage',
            }"
          />
          <sidebar-item
            v-if="
              permissions.includes('import') ||
                permissions.includes('process salary')
            "
            :link="{
              name: 'นำเข้าข้อมูล',
              path: '/payroll/importpage',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตรวจสอบสลิปเงินเดือน',
              path: '/payroll/checkslippage',
            }"
          />
          <!-- <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'จัดการ',
              path: '/payroll/managepayroll',
            }"
          /> -->
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตั้งค่าวันหยุดประจำสัปดาห์',
              path: '/payroll/setting-staff-weekend',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตั้งค่าประกันสังคม',
              path: '/payroll/setting-staff-sso',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตั้งค่าการคำนวณภาษี',
              path: '/payroll/setting-limittax',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตั้งค่า OT',
              path: '/payroll/setting-ot',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตั้งค่า OT เหมาและค่านวดสปา',
              path: '/payroll/SettingOTFlatRate',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{
              name: 'ตั้งค่าผูกรหัสสแกนลายนิ้วมือ',
              path: '/payroll/settingScan',
            }"
          />
          <sidebar-item
            v-if="permissions.includes('process salary')"
            :link="{ name: 'คำนวณเงินเดือน', path: '/payroll/salary-process' }"
          />
          <sidebar-item
            v-if="
              permissions.includes('process salary') ||
                permissions.includes('view report')
            "
            :link="{ name: 'รายงานเงินเดือน', path: '/payroll/salary-report' }"
          />
          <sidebar-item
            v-if="
              permissions.includes('process salary') ||
                permissions.includes('view report')
            "
            :link="{
              name: 'รายงาน OT เหมา และค่านวดสปา',
              path: '/payroll/flatreatspa-report',
            }"
          />
          <!-- <sidebar-item
            v-if="
              permissions.includes('process salary') ||
                permissions.includes('view report')
            "
            :link="{
              name: 'รายงาน Canteen',
              path: '/payroll/canteen-report',
            }"
          /> -->
        </sidebar-item>
        <!-- <sidebar-item
          opened
          :link="{ name: 'Debugs', image: image }"
          v-if="permissions.includes('debugs')"
        >
          <sidebar-item :link="{ name: 'debug', path: '/debugs/debug' }" />
        </sidebar-item> -->
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar
        :totalnotify="totalNotify"
        :selectLang="lang"
        @setLang="setlang"
      />

      <!-- <fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin> -->

      <div :class="{ content: !$route.meta.hideContent }">
        <zoom-center-transition :duration="200" mode="out-in">
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`, {
      swipeEasing: true,
    });
    // document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 1000);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.userAgent.indexOf('Win');
  var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
  if (isWindows && !mac) {
    // if we are on windows OS we activate the perfectScrollbar function
    // initScrollbar('sidebar');
    initScrollbar('sidebar-wrapper');
    // initScrollbar('main-panel');

    docClasses.add('perfect-scrollbar-off');
    // docClasses.add('perfect-scrollbar-on');
  } else {
    docClasses.add('perfect-scrollbar-off');
  }
}

import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import MobileMenu from './Extra/MobileMenu.vue';
import FixedPlugin from '../../FixedPlugin.vue';
import UserMenu from './Extra/UserMenu.vue';
import Vuex from 'vuex';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  mixins: [employeeMixin],
  components: {
    TopNavbar,
    ContentFooter,
    // 'fixed-plugin': FixedPlugin,
    MobileMenu,
    UserMenu,
  },
  data() {
    return {
      is_spa_dep: [31, 77],
      approverOTOverLimit: [],
      employee_id: 0,
      totalNotify: 0,
      sidebarBackgroundColor: 'black',
      sidebarBackground: 'green',
      sidebarBackgroundImage:
        process.env.VUE_APP_APP_BASE_URL + '/img/sidebar-2.jpg',
      sidebarMini: true,
      sidebarImg: true,
      image: process.env.VUE_APP_APP_BASE_URL + '/img/list-menu.svg',
      profile: null,
      approverhas_emp: 0,
    };
  },
  computed: {
    ...Vuex.mapState({
      approverstotal: (state) => state.approvers.total,
      // leavetotal: (state) => state.staffleaves.leaveamout,
      // ottotal: (state) => state.ots.ottotal,
      // keepdaytotal: (state) => state.keepdays.keepdaytotal,
      // workingtotal: (state) => state.staffhasworks.workingtotal,
      me: (state) => state.profile.me,
      mepermissions: (state) => state.profile.mepermissions,
      lang: (state) => state.profile.lang,
    }),
    permissions() {
      var pms = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },
  methods: {
    ...Vuex.mapActions({
      settotal: 'approvers/settotal',
      setmepermis: 'profile/setmepermis',
      setlang: 'profile/setlang',
      // setLeaveAmout: 'staffleaves/setLeaveAmout',
      // setOTAmout: 'ots/setOTAmout',
      // setKeepdayAmout: 'keepdays/setKeepdayAmout',
      // setWorkingAmount: 'staffhasworks/setWorkingAmount',
    }),
    async get_Notify() {
      let params = {};
      await this.$store.dispatch('approvers/getPending', params).then((v) => {
        if (v) {
          this.settotal(v);
        } else {
          this.settotal(0);
        }
        if (this.$store.getters['approvers/total'] > 0) {
          this.settotal(this.$store.getters['approvers/total']);
        }
      });
    },
    async getApproverOTOverLimit() {
      let params = {};
      let approver = await this.$store.dispatch(
        'ots/approverOTOverLimit',
        params,
      );

      return approver;
    },
    async getUniqueApprove() {
      loading_start();
      // this.profile = await this.getProfileData(this.me.employee_id);
      let params = {
        ...{
          com_id: this.profile?.[0].com_id ? this.profile?.[0]?.com_id : {},
        },
        ...{ approver_id: this.profile?.[0].id ? this.profile?.[0]?.id : {} },
      };

      loading_start();
      let staffList = await this.$store.dispatch('uniqueapprover/list', params);
      loading_close();
      return staffList;
    },
    checkDevice() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad||playbook|silk/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4),
          )
        )
          check = true;
      })(navigator.userAgent);
      return check;
    },
    async sumtotalNotify(v) {
      if (v) {
        this.totalNotify = v;
      } else {
        this.totalNotify = this.approverstotal;
      }
      // total += this.leavetotal;
      // total += this.ottotal;
      // total += this.keepdaytotal;
      // total += this.workingtotal;
      // this.totalNotify = total;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    // getProfileDebounced: _.debounce(function () {
    //   this.$store.dispatch("profile/me")
    //   // this.getProfile().then(() =>{
    //   //   //this.setPermission(this.me);
    //   // });
    // }, 300),

    // async getProfile() {
    //   await this.$store.dispatch("profile/me")
    //   this.me = await this.$store.getters["profile/me"]
    // },

    // setPermission(val){
    //   val.permissions.map((permis) => {
    //     this.permissions.push(permis.name)
    //   });
    //   val.roles[0].permissions.map((permission) => {
    //     this.permissions.push(permission.name)
    //   });
    // },
  },
  async created() {
    this.profile = await this.getProfileData();
    this.employee_id = this.me?.employee_id;
    // console.log(this.profile[0]);
    this.approverhas_emp = await this.getUniqueApprove();
    let appOTLimit = await this.getApproverOTOverLimit();
    let arrAppID = [];
    appOTLimit.forEach((app) => {
      arrAppID.push(app.emp_id);
    });
    this.approverOTOverLimit = [...new Set(arrAppID)];
    // reinitScrollbar();
    // this.checkDevice();
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
    this.$material.locale.days = [
      'อาทิตย์',
      'จันทร์',
      'อังคาร',
      'พุธ',
      'พฤหัสบดี',
      'ศุกร์',
      'เสาร์',
    ];
    this.$material.locale.shortDays = [
      'อาทิตย์',
      'จันทร์',
      'อังคาร',
      'พุธ',
      'พฤหัสบดี',
      'ศุกร์',
      'เสาร์',
    ];
    this.$material.locale.shorterDays = [
      'อ.',
      'จ.',
      'อ.',
      'พ.',
      'พฤ.',
      'ศ.',
      'ส.',
    ];
    this.$material.locale.months = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];
    this.$material.locale.shortMonths = [
      'มกรา',
      'กุมภา',
      'มีนา',
      'เมษา',
      'พฤษภา',
      'มิถุนา',
      'กรกฎา',
      'สิงหา',
      'กันยา',
      'ตุลา',
      'พฤศจิกา',
      'ธันวา',
    ];
    this.$material.locale.cancel = 'ปิด';
    this.get_Notify();
  },
  updated() {
    // reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  destroyed() {},
  watch: {
    approverstotal: {
      handler: function(val) {
        this.sumtotalNotify(val);
      },
      deep: true,
    },
    sidebarMini() {
      this.minimizeSidebar();
    },
    me: {
      handler: function(val) {
        val.permissions.map((permis) => {
          this.permissions.push(permis.name);
        });
        val.roles[0].permissions.map((permission) => {
          this.permissions.push(permission.name);
        });
        this.setmepermis(this.permissions);
      },
      deep: true,
    },
    lang(val) {
      this.setlang(val);
    },
    // leavetotal: {
    //   handler: function(val) {
    //     this.setLeaveAmout(val);
    //     this.sumtotalNotify();
    //   },
    //   deep: true,
    // },
    // ottotal: {
    //   handler: function(val) {
    //     this.setOTAmout(val);
    //     this.sumtotalNotify();
    //   },
    //   deep: true,
    // },
    // keepdaytotal: {
    //   handler: function(val) {
    //     this.setKeepdayAmout(val);
    //     this.sumtotalNotify();
    //   },
    //   deep: true,
    // },
    // workingtotal: {
    //   handler: function(val) {
    //     this.setWorkingAmount(val);
    //     this.sumtotalNotify();
    //   },
    //   deep: true,
    // },
    // query:{
    //   handler: 'getProfileDebounced',
    //   immediate: true,
    // },
  },
};
</script>
<style lang="scss">
.date-time-picker:not(.inline) {
  position: relative !important;
}
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
