var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"createMemo"},[(true)?_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green add-memo"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("add_circle_outline")])],1),_c('h4',{staticClass:"title"}),_c('div',{staticClass:"text-right"},[_c('md-button',{staticClass:"md-primary md-dense",on:{"click":_vm.goBack}},[_vm._v(" Back to List ")])],1)]),_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter md-alignment-center-right"},[_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.previewPDF}},[_vm._v("Preview")])],1),_c('div',{staticClass:"md-layout md-gutter md-alignment-center-center"},[_c('div',{staticClass:"md-layout-item md-size-30"},[_c('md-field',[_c('label',[_vm._v("สาขา")]),_c('md-select',{model:{value:(_vm.branch_id),callback:function ($$v) {_vm.branch_id=$$v},expression:"branch_id"}},_vm._l((_vm.branchList),function(item,index){return _c('md-option',{key:index + '-' + item.bch_id,attrs:{"value":item.bch_id}},[_vm._v(" "+_vm._s(item.brname_th)+" ")])}),1)],1)],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('center',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogo),expression:"showLogo"}],staticStyle:{"width":"auto","height":"100px"},attrs:{"src":_vm.branchLogo[_vm.branch_id]}})])],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('center',[_c('span',{staticClass:"headMemo"},[_vm._v("MEMORANDAM")])])],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('center',[_c('b-table-simple',{staticClass:"memo-table"},[_c('b-thead',[_c('b-tr',[_c('b-td',[_c('p',[_vm._v("TO:")]),_c('b-input',{staticClass:"b-input-memo-table",model:{value:(_vm.toText),callback:function ($$v) {_vm.toText=$$v},expression:"toText"}})],1),_c('b-td',[_c('p',[_vm._v("DATE:")]),_c('b-datepicker',{staticClass:"b-datepicker-memo",attrs:{"date-format-options":{
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                      }},model:{value:(_vm.dateMemo),callback:function ($$v) {_vm.dateMemo=$$v},expression:"dateMemo"}})],1)],1),_c('b-tr',[_c('b-td',[_c('p',[_vm._v("FROM:")]),_c('b-input',{staticClass:"b-input-memo-table",model:{value:(_vm.fromText),callback:function ($$v) {_vm.fromText=$$v},expression:"fromText"}})],1),_c('b-td',[_c('p',[_vm._v("PAGE:")]),_c('b-input',{staticClass:"b-input-memo-table",model:{value:(_vm.pageText),callback:function ($$v) {_vm.pageText=$$v},expression:"pageText"}})],1)],1),_c('b-tr',[_c('b-td',[_c('p',[_vm._v("SUBJECT:")]),_c('b-input',{staticClass:"b-input-memo-table",model:{value:(_vm.subjectText),callback:function ($$v) {_vm.subjectText=$$v},expression:"subjectText"}})],1),_c('b-td',[_c('p',[_vm._v("CC:")]),_c('b-input',{staticClass:"b-input-memo-table",model:{value:(_vm.cc),callback:function ($$v) {_vm.cc=$$v},expression:"cc"}})],1)],1)],1)],1)],1)],1),(_vm.check_showeditor)?_c('div',{staticClass:"md-layout-item md-size-100"},[_c('hr'),_c('PdfmeEditor',{ref:"pdfmeEditor",attrs:{"baseTemplate":_vm.baseTemplate,"contentTemplate":_vm.pdfme_template},on:{"getPdfTemplate":function($event){return _vm.getPdfTemplate($event)},"onMountedPdfme":_vm.onMountedPdfme},model:{value:(_vm.pdfme_template),callback:function ($$v) {_vm.pdfme_template=$$v},expression:"pdfme_template"}}),_c('PrioritySettings',{ref:"prioritySettings",attrs:{"title":'อนุมัติ',"priorityGroups":_vm.staffPriorityGroups,"radioOptions":[
              { value: 'createBy', name: 'Create By' },
              { value: 'acknowledge', name: 'Acknowledge' },
              { value: 'approver', name: 'Approver' },
            ]},on:{"staffs":_vm.getStaffs,"onMountedPrioritySettings":_vm.onMountedPrioritySettings}}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveMemo(false)}}},[_vm._v(" Save as Draft ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveMemo(true)}}},[_vm._v(" Send for Approval ")])],1)],1):_vm._e()])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }