<template>
  <div>
    <!-- Filter employee -->
    <filter-employee
      :headLabel="headLabel"
      @childBranches="setBranches"
      @childDepartment="setDepartment"
      @childStaffs="setStaffs"
      :permissionsList="permissions"
      v-if="permissions.includes('view select staff')"
    >
    </filter-employee>

    <md-dialog
      style="min-height: 60%;"
      :md-active.sync="showEdit_time"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem">
        แก้ไขเวลา เข้า-ออกงาน
      </md-dialog-title>
      <md-dialog-content v-if="data_time_edit" style="font-size: 1.2rem">
        วันที่แก้ไข :
        {{
          moment(data_time_edit.date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format(
            'DD/MM/YYYY',
          )
        }}
        <br />
        <hr />
        แก้ไขเวลา :
        <br />
        <md-field>
          <VueCtkDateTimePicker
            id="time_edit"
            v-model="time_edit"
            :only-time="true"
            :noLabel="true"
            :minute-interval="1"
            :format="'HH:mm'"
            :formatted="'HH:mm'"
            :label="'เวลาเข้า-ออกงาน'"
            :input-size="'sm'"
          />
        </md-field>
        <md-field>
          <label>หมายเหตุ</label>
          <md-input v-model="remark"></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-success"
          @click="
            edittime_insert(
              data_time_edit.id,
              data_time_edit.date,
              data_time_edit.machine_number,
            )
          "
        >
          บันทึก
        </md-button>
        <md-button class="md-danger" @click="showEdit_time = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Date -->
    <div class="md-layout spacing md-alignment-left">
      <div class="md-layout-item md-size-20"></div>
      <div
        class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
      >
        <md-datepicker v-model="fromdate" md-immediately>
          <label>วันที่เริ่ม</label>
        </md-datepicker>
      </div>
      <div
        class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
      >
        <md-datepicker
          v-model="todate"
          md-immediately
          :md-disabled-dates="disabledToDates"
        >
          <label>วันที่สิ้นสุด</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-size-10">
        <md-progress-spinner
          :md-diameter="50"
          :md-stroke="3"
          md-mode="indeterminate"
          :md-value="100"
          v-show="showProgressBar"
        >
        </md-progress-spinner>
      </div>
    </div>

    <!-- Button -->
    <div class="md-layout spacing md-alignment-center">
      <div
        class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
      >
        <b-button size="lg" variant="info" @click="searchTimeInout">
          Search
        </b-button>
      </div>
    </div>

    <!-- Display table -->
    <div class="md-layout spacing" v-if="Object.keys(tableData).length > 0">
      <div class="md-layout-item md-size-100">
        <!-- <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content> -->
        <md-table table-header-color="green" class="table-striped table-hover">
          <md-table-cell>วันที่</md-table-cell>
          <md-table-cell>เวลาสแกน</md-table-cell>
          <md-table-row
            v-for="(items, name, index) in tableData"
            :key="{ index } + name"
          >
            <md-table-cell>
              {{ name }}
            </md-table-cell>
            <md-table-cell>
              <md-table-cell
                v-for="(time, index) in items"
                :key="{ index } + time.id_staffinouts"
              >
                <span
                  v-if="
                    permissions.includes('process salary') &&
                      time.machine_number == '0'
                  "
                  @click="
                    Edit_time_machine(
                      time.time_record,
                      time.id_staffinouts,
                      name,
                      time.machine_number,
                      time.remark,
                    )
                  "
                  ><md-icon
                    v-if="
                      permissions.includes('process salary') &&
                        time.machine_number == '0'
                    "
                    >edit</md-icon
                  ></span
                >
                <span
                  class="md-rose md-just-icon md-round"
                  v-if="
                    permissions.includes('process salary') &&
                      time.machine_number == '0'
                  "
                  @click="
                    delete_time_machine(
                      time.id_staffinouts,
                      time.machine_number,
                    )
                  "
                >
                  <md-icon
                    v-if="
                      permissions.includes('process salary') &&
                        time.machine_number == '0'
                    "
                    >delete</md-icon
                  >
                </span>
                {{ time.time_record }}
              </md-table-cell>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <!-- </md-card-content>
        </md-card> -->
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from '@/components';
import Pagination from '@/components/Pagination';
import moment from 'moment';
import * as XLSX from 'xlsx';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import Vuex from 'vuex';
import { loading_start, loading_close } from '@/utils/loading.js';
import $ from 'jquery';
import Swal from 'sweetalert2';

export default {
  components: {
    'filter-employee': FilterEmployee,
    'VueCtkDateTimePicker': VueCtkDateTimePicker,
  },
  mixins: [employeeMixin],

  data() {
    return {
      showEdit_time: false,
      edittime_data: null,
      remark: '',
      headLabel: 'เวลาก่อนประมวลผล',
      tableData: [],
      total: 1,
      moment: moment,
      time_in: null,
      time_out: null,
      time_edit: null,
      data_time_edit: {
        type: 'time_edit',
        id: null,
        date: null,
      },
      fromdate: null,
      todate: null,
      showProgressBar: false,
      isPermission: true,
      branches: [
        {
          id: null,
          type: 'branches',
        },
      ],
      departments_id: null,
      branch_id: null,
      staffs: [],
      disabled_export: true,
      disabled_process: true,
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      user: null,
      //permissions: [],
    };
  },

  props: {
    permissions: Array,
    employeeID: null,
  },

  created() {},
  watch: {
    employee_id: function() {
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
    departments_id: function() {
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
    fromdate: function() {
      if (this.todate) {
        var todate = moment(this.todate, 'DD/MM/YYYY');
        if (moment(todate).diff(moment(this.fromdate), 'days') < 0) {
          this.todate = this.fromdate;
        }
      }
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
    todate: function() {
      if (
        (this.employeeID > 0 || this.departments_id > 0) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
  },
  methods: {
    /*
     * find staff time in out
     */
    async searchTimeInout() {
      if (!this.branches) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
        return false;
      } else if (!this.employeeID) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      } else if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      }

      let params = {
        filter: {
          ...(this.employeeID ? { emp_id: this.employeeID } : {}),
          ...(this.fromdate
            ? { fromdate: moment(this.fromdate).format('YYYY-MM-DD') }
            : {}),
          ...(this.todate
            ? { todate: moment(this.todate).format('YYYY-MM-DD') }
            : {}),
        },
      };
      this.showProgressBar = true;

      try {
        await this.$store
          .dispatch('stafftimeinout/rawtime', params)
          .then((data) => {
            var nObj = {};
            $.each(data, (i, obj) => {
              nObj[moment(i).format('DD/MM/YYYY')] = obj;
            });
            this.tableData = [];
            if (nObj) {
              this.tableData = nObj;
            } else {
              this.$store.dispatch('alerts/error', 'No data!!!');
            }
            this.showProgressBar = false;
          });
      } catch (e) {
        this.showProgressBar = false;
        this.disabled_export = true;
        await this.$store.dispatch('alerts/error', 'No data!!!');
      }
    },

    // Export excel
    onExport() {
      const data = this.tableData.map((d) => ({
        date: d.date,
        in: d.timein,
        out: d.timeout,
        break: d.timebreak,
        finishbreak: d.timefinishbreak,
        status: d.workstatus,
        minutetolate: d.timetolatein,
        minuteoff: d.timetolateout,
        workingtime:
          moment(d.condition.start_date + ' ' + d.condition.start_time).format(
            'HH:mm',
          ) +
          ' - ' +
          moment(d.condition.start_date + ' ' + d.condition.finish_time).format(
            'HH:mm',
          ),
      }));
      const dataWS = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      const file_name = this.employeeID + '_timeinout_export' + '.xlsx';
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, file_name);
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
    },

    /**
     * set employee id value from child component
     */
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employeeID = v.id;
      } else {
        this.staffs = [];
        this.employeeID = null;
      }
    },

    async getProfile() {
      await this.$store.dispatch('profile/me');
    },

    /*show dialog edit time for machine = 0   */
    async Edit_time_machine(time, id, name, machine_number, remark) {
      this.time_edit = time;
      this.data_time_edit.id = id;
      this.data_time_edit.date = name;
      this.data_time_edit.machine_number = machine_number;
      this.remark = remark;
      this.showEdit_time = true;
    },
    async edittime_insert(id, date, machine_number) {
      var datetime_record = moment(date + ' ' + this.time_edit, [
        'YYYY-MM-DD hh:mm:ss A',
        'DD/MM/YYYY hh:mm:ss A',
      ]).format('YYYY-MM-DD hh:mm:ss A');

      datetime_record.replace('ก่อนเที่ยง', 'AM');
      datetime_record.replace('หลังเที่ยง', 'PM');

      var day_time = moment(date + ' ' + this.time_edit, [
        'YYYY-MM-DD hh:mm:ss A',
        'DD/MM/YYYY hh:mm:ss A',
      ]).format('YYYY-MM-DD HH:mm:ss');

      var time = moment(this.time_edit).format('hh:mm:ss');

      let params = {
        update_id: id,
        datetime_record: datetime_record,
        time_record: this.time_edit,
        machine_number: machine_number,
        day_time: day_time,
        remark: this.remark,
      };
      let resp = await this.$store.dispatch(
        'stafftimeinout/updateTimeMachin',
        params,
      );
      if (resp == 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else {
        await this.$store.dispatch('alerts/success', 'สำเร็จเรียบร้อยแล้ว');
        this.showEdit_time = false;
        this.searchTimeInout();
      }
    },
    async delete_time_machine(id, machine) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              ...{ id_delete: id },
              ...{ machine_number: machine },
            };
            let resp = await this.$store.dispatch(
              'stafftimeinout/deleteTimeMachin',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Time request has been deleted.',
                'success',
              );
              this.searchTimeInout();
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'Your imaginary Time request is safe :)',
              'error',
            );
          }
        });
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
};
</script>

<style scoped>
.table-transparent {
  background-color: transparent !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.stop-work {
  color: red;
}
.weekend-work {
  color: #ffd000;
}
.change-day {
  color: #ff932e;
}
.leave {
  color: #508dff;
}
.notscan {
  color: #d400ff;
}
.late {
  color: #c4284a;
}
.keep-day {
  color: #00dd30;
}
</style>
